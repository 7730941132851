import { ICatalogueThemesResponse } from './../../../core/models/thematic.model';
import { Injectable } from '@angular/core';
import { ITrendsResponse } from '../../../core/models/thematic.model';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ThematicService {

  constructor(private http: HttpClient) { }

  getDevelopedMarketsData(){
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/developed/2023',{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType:'json'
    })
  }

  getGlobalMarketsData(){
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/ipc/2023',{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType:'json'
    })
  }

  getCatalogueThemes () {
    return this.http.get<ICatalogueThemesResponse>("/backend/thematicV3/catalogue/list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      responseType: "json",
    })
  }

  addfavorites(theme_id: number) {
    var params = new HttpParams();
    params = params.append("theme_id", theme_id)
    return this.http.post("/backend/thematicV3/favorites/add", {}, {
      headers:
      {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      params: params,
      responseType: "json"
    });
  }

  removefavorites(theme_id: number) {
    var params = new HttpParams();
    params = params.append("theme_id", theme_id)
    return this.http.post("/backend/thematicV3/favorites/remove", {}, {
      headers:
      {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      params: params,
      responseType: "json"
    });
  }

}
