<app-main-navbar></app-main-navbar>

<div class="container-fluid">
  <!-- <div class="row align-items-center" style="height:60px;background-color: #ffffff;">
    <div class="col-2">
      <img src="assets/quant_ip_logo.png" class="img-fluid logo-img mt-2">

    </div>
    <div class="col">

      <button type="button" class="btn " style="background-color:#FEF7FF ;">
       Thematic Search
      </button>

    </div>
  </div> -->
  <div class="row cards-row" >
    <div class="col cards-col">



      <div class="card shadow" style="width: 23rem;">
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">Theme Catalogue</h5>
          <p class="card-text">Use the Quant IP Theme Catalogue consisting of more than a thousand pre-defined themes to find innovation leaders in each technology
            .<br> <br>
            Our Theme Catalogue includes technology themes throughout the innovation landscape, including green technology themes for sustainable investing.
          </p>
            <div class="d-flex justify-content-end">
              <button class="btn" (click)="openCatalogueSearch()" >Open</button>
            </div>
        </div>
      </div>

      <div class="card shadow" style="width: 23rem;">
        <div class="card-body d-flex flex-column">
          <h5 class="card-title">Custom Search</h5>
          <p class="card-text">Use keywords to define a custom theme covering the specific technologies you are interested in.<br>

            <br>
            From Quantum Computing to Offshore Wind Energy, find the most active companies in any custom technology area.</p>
            <div class="d-flex justify-content-end">
              <button class="btn" (click)="openCustomSearch()" >   Open </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>


