<app-main-navbar></app-main-navbar>

<div class="container-fluid">
  <div class="row" style=" display: flex; flex-grow: 1;">
    <div class="col-auto" style="height:100vh;max-width: 300px;padding: 0;">
      <div class="sidenav">
        <app-idea-generation-sidebar></app-idea-generation-sidebar>
      </div>
    </div>


    <div class="col content">
      <div class="main-content">
        <!-- Dynamically load components based on the selected option -->
        <ng-container *ngIf="selectedOption === 'themeTrends'">
          <app-theme-trends [market]="selectedMarket"></app-theme-trends>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'topThemes'">
          <app-top-themes [market]="selectedMarket"></app-top-themes>
        </ng-container>

        <ng-container *ngIf="selectedOption === 'qipHighlights'">
          <app-cluster-treemap [tabId]="'qipTabId'" [query]="qipHighlightsTheme" [entityids]="null" [tab_name]="null"
            [qipHighlights]="true">
          </app-cluster-treemap>
        </ng-container>


        <!-- Placeholder content when no option is selected -->
        <div *ngIf="!selectedOption">
          <h3>Select an option from the sidebar</h3>
        </div>
      </div>
    </div>
  </div>
</div>