import { Component } from '@angular/core';
import { IdeaGenStateService } from '../../idea-gen-state.service';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ThemeTrendsComponent } from '../theme-trends/theme-trends.component';
import { TopThemesComponent } from '../top-themes/top-themes.component';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { ClusterTreemapComponent } from '../../../results/components/result-sections/clustering/cluster-treemap/cluster-treemap.component';
@Component({
  selector: 'app-idea-generation-home',
  standalone: true,
  imports: [CommonModule,SidebarComponent,ThemeTrendsComponent,TopThemesComponent,MainNavbarComponent,ClusterTreemapComponent],
  templateUrl: './idea-generation-home.component.html',
  styleUrl: './idea-generation-home.component.css'
})
export class IdeaGenerationHomeComponent {
  selectedOption: string = '';
  selectedMarket: string = '';
  qipHighlightsTheme:string = '';
constructor(private stateService:IdeaGenStateService){
   // Subscribe to changes in selected option and market
   this.stateService.selectedOption$.subscribe((option) => {
    this.selectedOption = option;
  });

  this.stateService.selectedMarket$.subscribe((market) => {
    this.selectedMarket = market;
  });

  this.stateService.selectedQipHighlight$.subscribe((theme)=>{
    this.qipHighlightsTheme = theme
  })

}
}
