import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ICompanyResponse } from '../../../core/models/company.model';
import { IFootprintDetailedResponse, IinnovationActivityResponse, IinnovationQualityResponse, IKeywordsResponse, IPeerGroupCompetitorsResponse, ITechnologyFootprint, ITechnologyFootprintResponse, ITopCompaniesResponse } from '../../../core/models/results.model';


@Injectable({
  providedIn: 'root'
})
export class GeneralInnovationActivityResultsService {

  constructor(private http: HttpClient) { }


  testSearch(name: string): Observable<ICompanyResponse> {
    let params = new HttpParams()
      .set("num_matches", "100")
      .set("name", name ?? '');

    return this.http.get<ICompanyResponse>("/backend/company/search", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("current_user_token")}`,
      },
      params: params,
      responseType: "json",
    }).pipe(
      catchError(error => {
        console.error('Error occurred during test search:', error);
        return throwError(() => new Error('Error during test search.'));
      })
    );
  }


  getInnovationActivity(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IinnovationActivityResponse> {
    return this.http.post<IinnovationActivityResponse>('/backend/results/innovation/activity',{'query':query,'entityids':entityids}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      responseType: 'json',
    }
    )
}


getTechnologyFootprint(query: string | null | undefined,entityids:string[]|null|undefined): Observable<ITechnologyFootprintResponse> {
  return this.http.post<ITechnologyFootprintResponse>('/backend/results/innovation/techfootprint',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}

getKeywords(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IKeywordsResponse> {
  return this.http.post<IKeywordsResponse>('/backend/results/innovation/keywords',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}


getFootprintDetailed(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IFootprintDetailedResponse> {
  return this.http.post<IFootprintDetailedResponse>('/backend/results/innovation/footprint/detailed',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}


getInnovationQuality(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IinnovationQualityResponse> {
  return this.http.post<IinnovationQualityResponse>('/backend/results/innovation/quality',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}

getTopCompanies(query: string | null | undefined,entityids:string[]|null|undefined): Observable<ITopCompaniesResponse> {
  return this.http.post<ITopCompaniesResponse>('/backend/results/top_companies',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}

getCompetitiveBenchmarkingPeerGroup(entityids:string[]|null|undefined,gsubind:string[]|null|undefined): Observable<IPeerGroupCompetitorsResponse> {
  return this.http.post<IPeerGroupCompetitorsResponse>('/backend/results/competitive_benchmarking/peergroup',{'gsubind':gsubind,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}



}
