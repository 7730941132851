<nav class="navbar navbar-expand-lg navbar-light pa-0  main">
  <a class="navbar-brand" routerLink="/home">
    <img src="assets/QuantIP_Logo_white.svg" class="d-inline-block align-top img-fluid logo-img mt-2" alt="Quant IP Logo">
  </a>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="btn-group" dropdown>
      <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle"
        aria-controls="dropdown-basic">
        New Search <span class="caret"></span>
      </button>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
        <li role="menuitem"><a class="dropdown-item" routerLink="/company-search" routerLinkActive="active"
            ariaCurrentWhenActive="page">Company Search</a></li>
        <li role="menuitem"><a class="dropdown-item" routerLink="/thematic-search" routerLinkActive="active"
            ariaCurrentWhenActive="page">Thematic Search</a></li>
        <li role="menuitem"><a class="dropdown-item" routerLink="/idea-generation" routerLinkActive="active"
            ariaCurrentWhenActive="page">Idea Generation</a></li>



      </ul>
    </div>
  </div>

</nav>



<router-outlet></router-outlet>
