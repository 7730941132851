import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree ,Router} from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private AuthService: AuthService
) {}


canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('current_user_token');
  if (user && token) {
      return true;
  }
  this.router.navigate(['/login']), { queryParams: { returnUrl: state.url } };
  return false;

}


}
