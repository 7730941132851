import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { IClusteringFootprintDetailedResponse, IClusteringFootprintResponse, IClusterPeerGroupsResponse, ITechFootprintDetailedResponse } from '../../../../../core/models/clustering.model';
import { IFootprintDetailedResponse, IinnovationQualityResponse, ITopCompaniesResponse, IPeerGroupCompetitorsResponse } from '../../../../../core/models/results.model';



@Injectable({
  providedIn: 'root'
})
export class ClusteringService {

  constructor(private http: HttpClient) { }




getTechFootprintDetailed(query: string | null | undefined,entityids:string[]|null|undefined): Observable<ITechFootprintDetailedResponse> {
  return this.http.post<ITechFootprintDetailedResponse>('/backend/results/cluster/techfootprint/detailed',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}

getClusteringDetailed(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IClusteringFootprintDetailedResponse> {
  return this.http.post<IClusteringFootprintDetailedResponse>('/backend/results/cluster/footprint/detailed',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}

getClusteringFootprint(query: string | null | undefined,entityids:string[]|null|undefined): Observable<IClusteringFootprintResponse> {
  return this.http.post<IClusteringFootprintResponse>('/backend/results/innovation/cluster/footprint',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}



getPeerGroup(entityids:string[]|null|undefined,gsubind:string[]|null|undefined): Observable<IClusterPeerGroupsResponse> {
  return this.http.post<IClusterPeerGroupsResponse>('/backend/results/cluster/peergroup',{'gsubind':gsubind,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}


getClusterTreemapData(query: string | null | undefined,entityids:string[]|null|undefined): Observable<any> {
  return this.http.post<any>('/backend/results/cluster/treemap',{'query':query,'entityids':entityids}, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("current_user_token"),
    },
    responseType: 'json',
  }
  )
}


}
