import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITrendsResponse } from '../../../core/models/thematic.model';
import { ItopThemesResponse } from '../../../core/models/idea-generation.model';

@Injectable({
  providedIn: 'root'
})
export class IdeaGenerationServiceService {

  constructor(private http: HttpClient) { }

  getDevelopedMarketsData(){
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/developed/2023',{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType:'json'
    })
  }

  getGlobalMarketsData(){
    return this.http.get<ITrendsResponse>('/backend/thematicTrends/ipc/2023',{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType:'json'
    })
  }

  getDevelopedMarketsTopThemes(){

 return this.http.get<ItopThemesResponse>('/backend/thematicTrends/top-themes/developed',{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("current_user_token")
      },
      responseType:'json'
    })
  }
  getGlobalMarketsTopThemes(){

    return this.http.get<ItopThemesResponse>('/backend/thematicTrends/top-themes/global',{
         headers:{
           Authorization: "Bearer " + localStorage.getItem("current_user_token")
         },
         responseType:'json'
       })
     }
}
