import { Component, OnInit } from '@angular/core';
import {MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '../../../../../Material-Module';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-eula-dialog',
  standalone: true,
  imports: [CommonModule,MaterialModule,MatDialogModule],
  templateUrl: './eula-dialog.component.html',
  styleUrls: ['./eula-dialog.component.css']
})
export class EULADialogComponent implements OnInit {

 constructor(public dialogRef:MatDialogRef<EULADialogComponent>) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

}
