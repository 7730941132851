<div class="container-fluid">
  <div class="row">
    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name | titlecase}}    </h1>

    <div class="col-12" style="margin:20px;">
      <div *ngIf=" (tabs$ | async)?.search_type=='company'">
        This page provides a closer look at what the company is focusing on in its innovation activity. The top keywords
        table displays the keywords that are more frequently seen in the text fields of the company’s patents in the
        last 3 years. The detailed footprint overview shows details of innovation activity in the technology fields the
        company is most active.


      </div>

      <div *ngIf=" (tabs$ | async)?.search_type=='theme'">
        This view gives a granular view on what is happening in this technology field through the most frequent keywords
        in the inventions, helping notice important areas of development within. These keywords can also be used to
        further specify your theme definitions.
      </div>
    </div>

    <div class="col m-10" style="margin:20px;">
      
      <br>
      <div class="card keywords" style="padding:20px;">

        <h3>Top Keywords</h3>
        <div class="alert alert-danger" role="alert" *ngIf="keywords_data_loading_failed">
          <p>
            Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>
        <div class="spinner-border text-primary" role="status"
          *ngIf="keywords.length==0 && !keywords_data_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <table class="table   table-hover table-condensed " *ngIf="keywords.length>0">
          <thead>
            <tr style="background-color: #96c242;">
              <th>Keyword</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of keywords | paginate: {id:'pag_1', itemsPerPage: 10, currentPage: paginator_1_page }">
              <td>{{item.ti_kw}}</td>
              <td>{{item.invention_count}}</td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="keywords.length>0">
        </pagination-controls>


      </div>
    </div>




    <div class="col m-10" style="margin:20px;">
      <div class="card keywords" style="padding:20px;">

        <h3>Detailed Footprint Activity</h3>
        <div class="alert alert-danger" role="alert" *ngIf="footprint_detailed_data_loading_failed">
          <p>
            Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>
        <div class="spinner-border text-primary" role="status"
          *ngIf="footprint_detailed_data.length==0 && !footprint_detailed_data_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <table class="table   table-hover table-condensed " *ngIf="footprint_detailed_data.length>0">
          <thead>
            <tr style="background-color: #96c242;">
              <th>Techfield</th>
              <th>Absolute Growth (3y)</th>
              <th>Footprint Percentage (3y)</th>
              <th>Quality Score (3y)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of footprint_detailed_data | paginate: {id:'pag_2', itemsPerPage: 10, currentPage: paginator_2_page }">
              <td>{{item.tf}}</td>
              <td>{{item.invention_count}}</td>
              <td>{{item.invention_count_percentage | number: '1.1-1'}} %</td>
              <td>
                {{item.quality_average | number:'1.1-1'}}
              </td>

            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_2" [maxSize]="5" (pageChange)="paginator_2_page = $event"
          style="align-items: flex-start;" *ngIf="footprint_detailed_data.length>0">
        </pagination-controls>


      </div>
    </div>
  </div>