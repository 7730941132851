import { ActionReducerMap } from '@ngrx/store';

import { AppState } from './app.state';
import { mainStateReducer } from './mainstate_reducer';
import { tabListReducer } from './tabs_reducer';

export const appReducer: ActionReducerMap<AppState> = {
  main: mainStateReducer,
  TabList: tabListReducer,
};
