import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take, switchMap, tap, of } from 'rxjs';
import {ITechnologyFootprint } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { Tab, AppState } from '../../../../../../state/app.state';
import { selectActiveTabDetails, selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { IClusteringFootprint } from '../../../../../../core/models/clustering.model';
import { ClusteringService } from '../clustering.service';
import { CommonModule } from '@angular/common';
import { PlotlyModule } from 'angular-plotly.js';

@Component({
  selector: 'app-clustering-innovation-activity',
  standalone: true,
  imports: [CommonModule,PlotlyModule],
  templateUrl: './clustering-innovation-activity.component.html',
  styleUrl: './clustering-innovation-activity.component.css'
})
export class ClusteringInnovationActivityComponent  implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  tabs$!: Observable<Tab | undefined>;
  showClusteringPlot: boolean=false;
  clusters_footprint_data_loading_failed: boolean= false;
  constructor(private store:Store<AppState>,private generalService:GeneralInnovationActivityResultsService,
    private clusteringService:ClusteringService
  ){

  }
  showTechnologyFootprint: boolean = false;
  techFootprintData: any = [];
ngOnInit(): void {

}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.tabs$ = this.store.select(selectActiveTabDetails);
    this.clusters_footprint_data_loading_failed=false;
    this.tech_footprint_data_loading_failed=false;
    this.showClusteringPlot=false;
    this.showTechnologyFootprint=false;
    this.techFootprintData=[];
    this.clusterFootprintData=[];
   this.techFootprint();
   this.clusterFootprint();

  }
}

tech_footprint_data_loading_failed:boolean = false;
techFootprint() {
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'technology_footprint_data')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.generalService.getTechnologyFootprint(this.query, this.entityids).pipe(
          tap((techFootprintData) => {

            if(techFootprintData.payload!=null){
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'technology_footprint_data',
                data: techFootprintData.payload
              }));

              this.techFootprintData = techFootprintData.payload;
              this.plotTechFootprint();
            }else{
              this.tech_footprint_data_loading_failed=true;
            }

          })
        );
      } else {
        this.tech_footprint_data_loading_failed=false;
        this.techFootprintData = data;
        this.plotTechFootprint();
        return of();
      }
    })
  ).subscribe({
    next: () => {
    },
    error: (err) => {
      this.tech_footprint_data_loading_failed=true;

    }
  });
}

techFootprintGraph: any = [];
plotTechFootprint() {
  const colors = ['#96c242', '#56751c', '#96c242', '#ff0022', '#6cd4ff', '#ffaa5a', '#FF9F1C', '#003B36', '#96c242', '#5e1075', '#000000'];
  const footprint: ITechnologyFootprint[] = this.techFootprintData;
  const years = [...new Set(footprint.map(item => item.ype))];

  const techfields = [...new Set(footprint.map(item => item.tf))];

  const techfieldColors: { [key: string]: string } = {};
  techfields.forEach((techfield, index) => {
    techfieldColors[techfield] = techfield === 'Others' ? '#808080' : colors[index % colors.length];
  });

  const totalCounts: { [key: number]: number } = {};
  years.forEach(year => {
    totalCounts[year] = footprint.reduce((acc: number, item: ITechnologyFootprint) => {
      if (item.ype === year) {
        return acc + item.invention_count;
      }
      return acc;
    }, 0);
  });

  const data = techfields.map(techfield => {
    const techfieldData = years.map(year => {
      const dataPoint = footprint.find(item => item.tf === techfield && item.ype === year);
      return dataPoint ? ((dataPoint.invention_count / totalCounts[year]) * 100).toFixed(2) : 0;
    });

    return {
      x: years,
      y: techfieldData,
      type: 'bar',
      name: techfield,
      marker: { color: techfieldColors[techfield] },
      hovertemplate: 'Year: %{x}<br>Value: %{y:.1f}%',
    };
  });

  const layout = {
    title: {
      text: 'Technology Footprint Over Time',
      font: {
          size: 20,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'
      }
  },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0.5,
            y: 1.04,
            xanchor: 'center',
            yanchor: 'bottom',
            text: '* The latest year is incomplete and considered year-to-date. ',
            showarrow: false,
            font: {
              size: 12,
              color: 'gray'
            }
          }],
    barmode: 'stack',
    xaxis: { title: 'Year', tickmode: 'linear', tick0: years[0], dtick: 1 },
    yaxis: { title: 'Percentage%' },
    //  width: 500,
    showlegend: true,
    legend: {
      orientation: 'h',
       y: -0.5,
      // x: 0.5,
      // xanchor: 'center',
      // yanchor: 'top',
      itemsizing: 'constant',

      font: { size: 10 },
    }
  };

  this.techFootprintGraph = {
    data: data,
    layout: layout
  };

  this.showTechnologyFootprint = true;
}



clusterFootprintData:IClusteringFootprint[]=[];
clusterFootprint() {
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'cluster_footprint_data')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.clusteringService.getClusteringFootprint(this.query, this.entityids).pipe(
          tap((clusterFootprintData) => {
            if(clusterFootprintData.payload!=null){
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'cluster_footprint_data',
                data: clusterFootprintData.payload
              }));

              this.clusterFootprintData = clusterFootprintData.payload;
              this.plotClusterFootprint();
            }else{
              this.clusters_footprint_data_loading_failed=true;
            }

          })
        );
      } else {
        this.clusters_footprint_data_loading_failed=false;
        this.clusterFootprintData = data;
        this.plotClusterFootprint();
        return of();
      }
    })
  ).subscribe({
    next: () => {
    },
    error: (err) => {
      this.clusters_footprint_data_loading_failed=true;

    }
  });
}


clusterFootprintGraph: any = [];
plotClusterFootprint() {
  const colors = ['#96c242', '#56751c', '#96c242', '#ff0022', '#6cd4ff', '#ffaa5a', '#FF9F1C', '#003B36', '#96c242', '#5e1075', '#000000'];
  const footprint: IClusteringFootprint[] = this.clusterFootprintData;
  const years = [...new Set(footprint.map(item => item.ype))];

  const clusters = [...new Set(footprint.map(item => item.tpc))];

  const techfieldColors: { [key: string]: string } = {};
  clusters.forEach((clusters, index) => {
    techfieldColors[clusters] = clusters === 'Others' ? '#808080' : colors[index % colors.length];
  });

  const totalCounts: { [key: number]: number } = {};
  years.forEach(year => {
    totalCounts[year] = footprint.reduce((acc: number, item: IClusteringFootprint) => {
      if (item.ype === year) {
        return acc + item.invention_count;
      }
      return acc;
    }, 0);
  });

  const data = clusters.map(clusters => {
    const clusterData = years.map(year => {
      const dataPoint = footprint.find(item => item.tpc === clusters && item.ype === year);
      return dataPoint ? ((dataPoint.invention_count / totalCounts[year]) * 100).toFixed(2) : 0;
    });

    return {
      x: years,
      y: clusterData,
      type: 'bar',
      name: clusters,
      marker: { color: techfieldColors[clusters] },
      hovertemplate: 'Year: %{x}<br>Value: %{y:.1f}%',
    };
  });

  const layout = {
    title: {
      text: 'Cluster Footprint Over Time',
      font: {
          size: 20,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'
      }
  },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0.5,
            y: 1.04,
            xanchor: 'center',
            yanchor: 'bottom',
            text: '* The latest year is incomplete and considered year-to-date. ',
            showarrow: false,
            font: {
              size: 12,
              color: 'gray'
            }
          }],
    barmode: 'stack',
    xaxis: { title: 'Year', tickmode: 'linear', tick0: years[0], dtick: 1 },
    yaxis: { title: 'Percentage%' },
    showlegend: true,
    legend: {
      orientation: 'h',
       y: -0.5,
      itemsizing: 'constant',

      font: { size: 10 },
    }
  };

  this.clusterFootprintGraph = {
    data: data,
    layout: layout
  };

  this.showClusteringPlot = true;
}
}

