import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { routes } from './app.routes';
import { appReducer } from './state/reducer';

import { HttpCancelService } from './core/interceptors/httpcancel.service';
import { ManageHttpInterceptor } from './core/interceptors/managehttp.interceptor';
import { provideEffects } from '@ngrx/effects';
import { SearchEffects } from './state/search_effect';
import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TabEffects } from './state/tab_effects';
import { versionCheckInterceptor } from './core/interceptors/version-check.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(appReducer),
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),

    provideEffects([SearchEffects,TabEffects]),
    {
      provide: HTTP_INTERCEPTORS,
      useValue: versionCheckInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true
    },
    HttpCancelService,
    
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    })
  ],
};
