import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EULADialogComponent } from '../eula-dialog/eula-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  templateUrl: './login-page.component.html',
  styleUrls:[ './login-page.component.css']
})
export class LoginPageComponent {
constructor(private dialog: MatDialog,private authService:AuthService,private router:Router){

}
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    agree: new FormControl(false, Validators.requiredTrue)
  });
  username : string = '';
  authenticated :boolean= false;
  showSpinner:boolean = false;


  openEulaDialog(): void {
    const dialogRef = this.dialog.open(EULADialogComponent, {
      width: '700px',
      disableClose:true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  login(){
    this.showSpinner=true;

    let username = this.loginForm.value.username
      ? this.loginForm.value.username
      : "";
    let password = this.loginForm.value.password
      ? this.loginForm.value.password
      : "";

    this.authService.login(username, password).subscribe({
      next:(res)=>{
        this.showSpinner=false;

        this.router.navigate(['/home'])
      },error:()=>{
        this.showSpinner=false;

        alert("Login failed. Please check your credentials");
      }
    })
  }

  logout(){
    this.authService.logout();
  }
}
