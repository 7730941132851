import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IinnovationQuality, ITopCompanies } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-thematic-competition-overview',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule],
  templateUrl: './thematic-competition-overview.component.html',
  styleUrl: './thematic-competition-overview.component.css'
})
export class ThematicCompetitionOverviewComponent  implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store:Store<AppState>,private apiService:GeneralInnovationActivityResultsService
  ){

  }
  paginator_1_page: number = 1;
  params: any;
ngOnInit(): void {
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.top_companies_loading_failed=false;
    this.top_companies_data=[];
   this.topCompanies();

  }
}


top_companies_loading_failed:boolean = false;
top_companies_data: ITopCompanies[] = [];
topCompanies() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'top_companies')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getTopCompanies(this.query, null).pipe(
            tap((res) => {

              if(res.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'top_companies',
                  data: res.payload
                }));
                this.top_companies_data = res.payload;
              }else{
                this.top_companies_loading_failed=true;
              }



              // this.plotInnovationQuality();
            })
          );
        } else {
          this.top_companies_data = data;
          // this.plotInnovationQuality();

          return of();
        }
      })
    ).subscribe({
      next: () => {
        // console.log('innovationQuality data detailed processing complete.');
      },
      error: (err) => {
        // console.error('Error fetching tech innovationQuality data detailed:', err);
        this.top_companies_loading_failed=true;
      }
    });
  }
}
