import { ICompany } from "../core/models/company.model";
import { ITopCompanies } from "../core/models/results.model";
import { ICatalogueThemes } from "../core/models/thematic.model";

// app.state.ts
export interface Tab {
  tabId: string;
  search_type: string;
  params: Iparams|null;
  tab_name:string;
  sidenav_results: {
    [key: string]: any;
  };
}

export interface Iparams{
  entityids:string[] |null|undefined,
  query:string|null|undefined,
  gsubind:string[]|null;
  entity_data:ICompany|null|undefined;
  query_data:ICatalogueThemes|null|undefined;
}

export interface MainState {
  activeTabId: string;
  activeSideTab: string;
  activeTab: Tab | null;
  // availableSidenavOptions: string[];
}

export interface AppState {
  main: MainState;
  TabList: Tab[];
}

export const initialMainState: MainState = {
  activeTabId: '',
  activeSideTab: '',
  activeTab: null
};

export const initialTabListState: Tab[] = [];

export const initialState: AppState = {
  main: initialMainState,
  TabList: initialTabListState
};


export const companySideNavList:string[]=['executive_summary','innovation_activity','tech_footprint','innovation_quality','competitive_benchmarking_peergroup','clustering_innovation_activity','clustering_tech_footprint','clustering_peer_group','cluster_treemap']
export const themeSideNavList:string[]=['innovation_activity','tech_footprint','competitive_overview','cluster_treemap']
