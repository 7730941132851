import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaterialModule } from '../../../../../Material-Module';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';

@Component({
  selector: 'app-thematic-home',
  standalone: true,
  imports: [MaterialModule,CommonModule,MainNavbarComponent],
  templateUrl: './thematic-home.component.html',
  styleUrl: './thematic-home.component.css'
})
export class ThematicHomeComponent {
constructor(private router:Router,private route: ActivatedRoute){

}
openCustomSearch(){
this.router.navigate(['/thematic-search/custom']);
}

openCatalogueSearch(){
  this.router.navigate(['/thematic-search/catalogue']);
}


}



