<div class="container-fluid">
    <div class="row">
  
      <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
        {{ tab_name | titlecase}}      </h1>
  
      <div class="col" style="margin:20px;">
        <div class="card footprint">
            <div class="alert alert-danger" role="alert" *ngIf="tech_footprint_data_loading_failed">
              <h3>Tech Footprint Over Time</h3>
    
              <p>
                Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
                contact
                <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
              </p>
            </div>
            <div class="spinner-border text-primary" role="status"
              *ngIf="techFootprintData.length==0 && !tech_footprint_data_loading_failed">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div *ngIf="showTechnologyFootprint">
              <plotly-plot [data]="techFootprintGraph.data" [layout]="techFootprintGraph.layout"></plotly-plot>
            </div>
          </div>
  
      </div>
      <div class="col" style="margin:20px;">
  
        <div class="card footprint">
          <div class="alert alert-danger" role="alert" *ngIf="clusters_footprint_data_loading_failed">
            <h3>Cluster Footprint Over Time</h3>
  
            <p>
              Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
              contact
              <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
            </p>
          </div>
          <div class="spinner-border text-primary" role="status"
            *ngIf="clusterFootprintData.length==0 && !clusters_footprint_data_loading_failed">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div *ngIf="showClusteringPlot">
            <plotly-plot [data]="clusterFootprintGraph.data" [layout]="clusterFootprintGraph.layout"></plotly-plot>
          </div>
        </div>
  
  
  
      </div>
  
  
    </div>