import { Action, createReducer, on } from '@ngrx/store';
import { changeActiveTab, changeActiveSideTab, loadSidenavData } from './action';
import { AppState, MainState, Tab } from './app.state';
import * as AppStateActions from './action';


export const initialTabListState: Tab[] = [];
export const tabListReducer = createReducer(
  initialTabListState,
  on(AppStateActions.addTab, (state, { tab }) => {
    const tabExists = state.some((existingTab) => existingTab.tabId === tab.tabId);
    if (tabExists) {
      return state;
    }
    return [...state, tab];
  }),
  on(AppStateActions.updateSidenavResults,(state,{tabId,sidenavKey,data})=>{
    return state.map((tab)=>{
      if(tab.tabId === tabId){
        return{
          ...tab,
          sidenav_results:{
            ...tab.sidenav_results,
            [sidenavKey]:data,
          },
        };
      }
      return tab;
    })
  }),
  on(AppStateActions.loadSidenavData, (state, { tabId, sidenavKey, data }) => {
    return state.map((tab) => {
      if (tab.tabId === tabId) {
        return {
          ...tab,
          sidenav_results: {
            ...tab.sidenav_results,
            [sidenavKey]: data,
          },
        };
      }
      return tab;
    });
  }),
  on(AppStateActions.removeTab, (state, { tabId }) => {
    return state.filter((tab)=>tab.tabId!=tabId)

  }),
  on(AppStateActions.logout, () => initialTabListState)

);


