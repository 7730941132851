<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="selectedTheme" *ngIf="selectedTheme">
        <h4>Selected Theme:  <strong>
          {{selectedTheme}}
        </strong> </h4> &nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn search-button" (click)="submitTheme()" *ngIf="selectedTheme">
          Search</button>
      </div>
      <div class="card  plotcard" style="min-height: 400px;">
        <div class="d-flex justify-content-center align-items-center">
          <div *ngIf="showSpinner" class="spinner-border text-primary"
            style="width: 6rem; height: 6rem;align-items: center;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <plotly-plot *ngIf="showPlot &&!showSpinner" [data]="themeCataloguePlot.data"
          [layout]="themeCataloguePlot.layout" (plotlyClick)="handleClick($event)">
        </plotly-plot>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col">


      <div class="plot-explanation">
        <div *ngIf="selectedMarket==='global' && showPlot &&!showSpinner" class="explanation-section">
          <h3>Global Market Analysis</h3>
          <p>The tree map visually represents the landscape of technology themes based on the growth in both relative
            and absolute terms. The size of each theme correspond to the number of inventions that were published
            related to that technology, indicating the magnitude of that theme. The colors depict the relative growth
            percentage over the past year. Red color represents the highest relative growth while the blue represents
            lower relative growth, highlighting which trends have grown faster in the year 2023.</p>
          <p>Each theme block contains information on its growth, growth percentage, top keywords, and leading companies
            in the global markets</p>
          <p>Clicking on a theme provides an option to conduct a thematic search, offering deeper insights such as top
            companies, top keywords, IPC classes, patent activity trends over the years, and technology footprint. This
            tool is designed to give users a comprehensive overview of the innovation landscape across different
            themes..</p>
        </div>

        <div *ngIf="selectedMarket==='developed' && showPlot &&!showSpinner" class="explanation-section">
          <h3>Developed Market Analysis</h3>
          <p>The tree map visually represents the landscape of various themes based on the number of inventions or
            absolute growth, with the size of each section indicating the magnitude of that theme. Colors depict the
            relative growth percentage over the past year. Red color represents the highest relative growth and blue
            represents lower relative growth, highlighting which trends have grown better in the year 2023.</p>
          <p>Each theme block contains information on its growth, growth percentage, top keywords, and leading companies
            - limited to those in developed markets(excluding Japan).</p>
          <p>Clicking on a theme provides an option to conduct a thematic search, offering deeper insights such as top
            companies, top keywords, IPC classes, patent activity trends over the years, and technology footprint. This
            tool is designed to give users a comprehensive overview of the innovation landscape across different themes.
          </p>
        </div>
      </div>





    </div>
