<!-- <div class="container-fluid">
  <div class="row h-100">

    <div class="col p-0">
      <img src="../assets/login_page.jpg" class="img-fluid h-100 w-100 " style="object-fit: cover;">
      <div class="logo-container">
        <img src="assets/quant_ip_logo.png" class="logo-img">
      </div>
    </div>
    <div class="col d-flex align-items-center justify-content-center">
      <div class="card login-card">
        <div class="card-header text-center">
          <h3 class="card-title">Login</h3>
        </div>
        <div class="card-body">

          <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="mb-3">
              <label for="username" class="form-label">Username</label>
              <input type="text" id="username" class="form-control" placeholder="Type in your username" required
                formControlName="username" />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input type="password" id="password" class="form-control" placeholder="Type in your password" required
                formControlName="password" />
            </div>


            <div class="mb-3">
              <div class="form-check">
                <input type="checkbox" id="agree" class="form-check-input" formControlName="agree" required />
                <label for="agree" class="form-check-label">
                  By logging in, I agree to the terms outlined in the <a href="javascript:void(0)" (click)="openEulaDialog()"
                  >End User License Agreement.</a>
                </label>
              </div>
              <div *ngIf="loginForm.get('agree')?.errors?.['required'] && (loginForm.get('agree')?.touched || showSpinner)" class="text-danger">
                You must agree to the terms before logging in.
              </div>
            </div>
            <div class="mb-3 text-center">
              <div *ngIf="showSpinner" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <button type="submit" class="btn btn-primary w-100" [disabled]="!loginForm.get('agree')?.value">
              Login
            </button>

          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="container-fluid">
  <div class="row flex-grow-1"> <!-- Use flex-grow-1 to take full height -->
    <div class="col p-0">
      <img src="../assets/login.png" class="img-fluid h-100 w-100" style="object-fit: cover;">
    </div>
  </div>
  <div class="logo-container">
    <img src="assets/QuantIP_Logo_white.svg" class="logo-img">
    <p class="mellon">Innovation Analytics Platform
    </p>
  </div>

  <!-- Centered Card -->
  <div class="card login-card" style="background:transparent;color:white;border:none">
    <div class="card-header text-center">
      <h3 class="card-title">Login</h3>
    </div>
    <div class="card-body">
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input type="text" id="username" class="form-control" placeholder="Type in your username" required
            formControlName="username" />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" id="password" class="form-control" placeholder="Type in your password" required
            formControlName="password" />
        </div>

        <div class="mb-3">
          <div class="form-check">
            <input type="checkbox" id="agree" class="form-check-input" formControlName="agree" required />
            <label for="agree" class="form-check-label">
              By logging in, I agree to the terms outlined in the <a href="javascript:void(0)" (click)="openEulaDialog()"
              >End User License Agreement.</a>
            </label>
          </div>
          <div *ngIf="loginForm.get('agree')?.errors?.['required'] && (loginForm.get('agree')?.touched || showSpinner)" class="text-danger">
            You must agree to the terms before logging in.
          </div>
        </div>
        <div class="mb-3 text-center">
          <div *ngIf="showSpinner" class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <button type="submit" class="btn btn-primary w-100" [disabled]="!loginForm.get('agree')?.value" style="background-color: #a2c614;color:black">
          Login
        </button>
      </form>
    </div>
  </div>
</div>
