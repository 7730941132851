import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
@Component({
  selector: 'app-results-main-header',
  standalone: true,
  imports: [CommonModule,RouterOutlet,BsDropdownModule,RouterLink,RouterLinkActive ],
  templateUrl: './results-main-header.component.html',
  styleUrl: './results-main-header.component.css'
})
export class ResultsMainHeaderComponent {

}
