<div class="container-fluid">
  <div class="row">

    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name | titlecase}}    </h1>

    <div class="col-12" style="margin:20px;">
      <div *ngIf=" (tabs$ | async)?.search_type=='company'">
        The graphs below provide an overview on the level of innovation output of the company over time and the
        breakdown of the output over various technology areas. While these graphs are important in order to understand
        the long-term strength of the company’s innovation processes, they are also important in spotting changes in the
        innovation strategy of the company.

      </div>

      <div *ngIf=" (tabs$ | async)?.search_type=='theme'">
        The graphs below provide an overview on the level of innovation output of this technology over time and the
        breakdown of the activity over various technology fields. While these graphs are important in order to
        understand the level of R&D investment going into the technology area to separate technologies that get real
        investment versus technologies that get news attention, they are also important in identifying sub-categories
        within technologies that are growing faster than others.
      </div>
      <br>
    </div>
    <div class="col" style="margin:20px;">
    


      <div class="card">
        <div class="alert alert-danger" role="alert" *ngIf="patent_activity_data_loading_failed">
          <h3>Patent Activity Over Years</h3>

          <p>
            Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>
        <div class="spinner-border text-primary" role="status"
          *ngIf="patentActivityData.length==0 && !patent_activity_data_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="showPlot">
          <plotly-plot [data]="patentActivityPlot.data" [layout]="patentActivityPlot.layout"></plotly-plot>
        </div>
      </div>

    </div>
    <div class="col" style="margin:20px;">

      <div class="card footprint">
        <div class="alert alert-danger" role="alert" *ngIf="footprint_data_loading_failed">
          <h3>Tech Footprint Over Time</h3>

          <p>
            Unfortunately, we weren't able to load the data. Please try again later, or for more assistance, please
            contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>
        <div class="spinner-border text-primary" role="status"
          *ngIf="footprintData.length==0 && !footprint_data_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="showTechnologyFootprint">
          <plotly-plot [data]="techFootprintGraph.data" [layout]="techFootprintGraph.layout"></plotly-plot>
        </div>
      </div>



    </div>


  </div>