<div class="container-fluid" style="background-color: white;">
  <div class="row">
    <app-results-main-header class="main-navbar"></app-results-main-header>
  </div>
  <div class="row r2">
    <div class="col-2">
      <app-results-sidebar></app-results-sidebar>
    </div>
    <div class="col results-main-body">
      <div class="row app-tab">
        <app-tab></app-tab>
      </div>
      <div class="row" style="margin-bottom:50px;">
        <ng-container *ngIf="(activeSideTab$ | async) === 'innovation_activity'">
          <app-innovation-activity [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
         >
          </app-innovation-activity>

        </ng-container>
        <ng-container *ngIf="(activeSideTab$ | async) === 'tech_footprint'">
          <app-technology-footprint [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null">
          </app-technology-footprint>
        </ng-container>




        <ng-container *ngIf="(activeSideTab$ | async) === 'innovation_quality'">
          <app-innovation-quality [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null">
          </app-innovation-quality>
        </ng-container>

        <ng-container *ngIf="(activeSideTab$ | async) === 'competitive_overview'">
          <app-thematic-competition-overview [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null">
          </app-thematic-competition-overview>
        </ng-container>

        <ng-container *ngIf="(activeSideTab$ | async) === 'competitive_benchmarking_peergroup'">
          <app-peer-group [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [gsubind]="(getParams() | async)?.params?.gsubind || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            >
          </app-peer-group>
        </ng-container>


        <ng-container *ngIf="(activeSideTab$ | async) === 'executive_summary'">
          <app-company-overview [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [gsubind]="(getParams() | async)?.params?.gsubind || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            >
          </app-company-overview>
        </ng-container>

<!-- Clustering  -->
        
        <ng-container *ngIf="(activeSideTab$ | async) === 'clustering_innovation_activity'">
          <app-clustering-innovation-activity [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            >
          </app-clustering-innovation-activity>
        </ng-container>


        <ng-container *ngIf="(activeSideTab$ | async) === 'clustering_peer_group'">
          <app-clustering-peer-group [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [gsubind]="(getParams() | async)?.params?.gsubind || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            >
          </app-clustering-peer-group>
        </ng-container>

        <ng-container *ngIf="(activeSideTab$ | async) === 'clustering_tech_footprint'">
          <app-clustering-tech-footprint [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            >
          </app-clustering-tech-footprint>
        </ng-container>

        
        <ng-container *ngIf="(activeSideTab$ | async) === 'cluster_treemap'">
          <app-cluster-treemap [tabId]="activeTab$ | async"
            [query]="(getParams() | async)?.params?.query || null"
            [entityids]="(getParams() | async)?.params?.entityids || null"
            [tab_name]="(getParams() | async)?.tab_name || null"
            [qipHighlights]="false"
            >
          </app-cluster-treemap>
        </ng-container>


      </div>

    </div>
  </div>
</div>
<router-outlet></router-outlet>

