import { ICatalogueThemes } from './../../../../core/models/thematic.model';
import { ThematicService } from './../../services/thematic.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { Store } from '@ngrx/store';
import { submitSearch } from '../../../../state/action';
@Component({
  selector: 'app-theme-catalogue',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, ReactiveFormsModule, FormsModule,MatFormFieldModule, MatSelectModule ,MatSlideToggleModule,MainNavbarComponent],
  templateUrl: './theme-catalogue.component.html',
  styleUrl: './theme-catalogue.component.css'
})
export class ThemeCatalogueComponent implements OnInit {

  searchForm: FormGroup;
  constructor(private thematicService: ThematicService, private fb: FormBuilder,private store:Store) {
    this.searchForm = this.fb.group({
      searchTerm: ['']
    });
  }

  ngOnInit(): void {
    this.getThemes();
    this.searchForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }


  paginator_1_page = 1;
  themes: ICatalogueThemes[] = [];
  filteredData: ICatalogueThemes[] = [];
  getThemes() {
    this.thematicService.getCatalogueThemes().subscribe({
      next: (res) => {
        this.themes = res.payload;
        this.populateDropdownOptions();
        this.applyFilters();

      },
      error: () => {
        alert('Failed to load themes!')
      }
    })
  }

  addTheme(selected: ICatalogueThemes) {
    let exists = this.selected_themes.some(el=>el.id === selected.id);
    if(!exists){
      this.selected_themes.push(selected);
      this.submitThemes();
    }
  }

  removeTheme(selected:ICatalogueThemes){
    this.selected_themes = this.selected_themes.filter((el)=>{
      return el.id!=selected.id;
    })  }


    favoriteFilter = false;
  classOptions: string[] = [];
  categoryOptions: string[] = [];
  populateDropdownOptions() {
    this.classOptions = Array.from(new Set(this.themes.map(theme => theme.class_)))
    this.categoryOptions = Array.from(new Set(this.themes.map(theme => theme.category)))
  }


  applyFilters() {
    // If no filters are applied, show all themes
    const selectedClasses = this.classFilters.value;
    const selectedCategories = this.categoryFilters.value;
    const searchTerm = this.searchForm.get('searchTerm')?.value.toLowerCase() || '';

    if (
      !this.favoriteFilter &&
      selectedClasses!.length === 0 &&
      selectedCategories!.length === 0 &&
      !searchTerm
    ) {
      // No filters are applied, show all themes
      this.filteredData = this.themes;
      return;
    }

    let data = this.themes;

    // Filter by search term
    data = data.filter(item => {
      return (
        (item.category && item.category.toLowerCase().includes(searchTerm)) ||
        (item.name && item.name.toLowerCase().includes(searchTerm)) ||
        (item.class_ && item.class_.toLowerCase().includes(searchTerm))
      );
    });

    // Filter by class, category, and favorite
    data = data.filter(theme => {
      const isClassMatch = selectedClasses!.length === 0 || selectedClasses!.includes(theme.class_);
      const isCategoryMatch = selectedCategories!.length === 0 || selectedCategories!.includes(theme.category);
      const isFavoriteMatch = !this.favoriteFilter || theme.favorite;

      return isClassMatch && isCategoryMatch && isFavoriteMatch;
    });

    this.filteredData = data;
  }



  filterThemes() {

    this.applyFilters();
  }

  disableFavoritesButton: boolean = false;
  toggleFavorite(item: ICatalogueThemes) {
    this.disableFavoritesButton = true;
    if (item.favorite == false) {
      this.thematicService.addfavorites(item.id).subscribe({
        next: (res) => {
         this.getThemes();

          this.disableFavoritesButton = false;
        },
        error: () => {
          this.disableFavoritesButton = false;
        },
      });
    }
    else {
      this.thematicService.removefavorites(item.id).subscribe({
        next: (res) => {
          item.favorite = !item.favorite;
         this.getThemes();
          this.disableFavoritesButton = false;
        },
        error: () => {
          this.disableFavoritesButton = false;
        },
      });
    }
  }

selected_themes:ICatalogueThemes[]=[];
paginator_2_page=1;

submitThemes(){
  const searchParams = {'themes':this.selected_themes};
  this.store.dispatch(submitSearch({searchParams,searchType:'theme'}));
}


classFilters = new FormControl('');
categoryFilters = new FormControl('');
}
