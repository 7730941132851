<app-main-navbar></app-main-navbar>

<div class="container-fluid" style="background-color: #F0F2F7;color:black">
  <div class="row  align-items-start" style="background-color: #F0F2F7;">
    <div class="col mt-5">
      <form class="row" [formGroup]="companySearchForm" (ngSubmit)="companySearch()">
        <div class="col-auto">
          <label class="visually-hidden">Company Name</label>
          <input type="text" class="form-control" id="name" placeholder="Company Name" formControlName="name">
        </div>
        or
        <div class="col-auto">
          <label class="visually-hidden">ISIN</label>
          <input type="text" class="form-control" id="isin" placeholder="ISIN" formControlName="isin">
        </div>
        or
        <div class="col-auto">
          <label for="url" class="visually-hidden">URL</label>
          <input type="text" class="form-control" id="url" placeholder="URL" formControlName="url">
        </div>
        <div class="col-auto" *ngIf="showSpinner">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="col-auto">
          <button type="submit" class="btn search-button mb-3"
            [disabled]="!companySearchForm.value.name && !companySearchForm.value.url &&!companySearchForm.value.isin ">Search</button>
        </div>
      </form>

      <!-- search results -->
      <div class="card" *ngIf="companies.length>0">
        <table class="table table-condensed table-hover">
          <thead>
            <tr>

              <th scope="col">Name</th> 
              <!-- NAME-WIDER;TICKER.SHORT -->
              <th scope="col">Ticker</th>
              <th scope="col">ISIN</th>
              <th scope="col">Country</th>
              <th scope="col">Region</th>
              <th scope="col">Type</th>
              <th scope="col">GICS Sub-Industry</th>

              <th scope="col">Inventions (all time)</th>

              <th scope="col">URL</th>


              <th scope="col">Quant IP Entity ID</th>
              <th scope="col">Capital IQ Company ID</th>
             

              <th scope="col">Action</th>
              <th scope="col" *ngIf="roles!.includes('Report') ">Report (V1)

                <div class="spinner-border text-primary" role="status" *ngIf="reportDownloadingV1">
                  <span class="visually-hidden">Loading...</span>
                </div>


              </th>
              <th scope="col" *ngIf="roles!.includes('ReportV2') ">Report (V2)

                <div class="spinner-border text-primary" role="status" *ngIf="reportDownloadingV2">
                  <span class="visually-hidden">Loading...</span>
                </div>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of companies  | paginate: {id:'pag_1', itemsPerPage: 10, currentPage: paginator_1_page }">

              <td>{{company.entity_name}}</td>
              <td>{{company.tickersymbol}}</td>
              <td>{{company.isin}}</td>

              <td>{{company.country}}</td>
              <td>{{company.region}}</td>
              <td>{{company.companytype}}</td>
              <td>{{company.subindustryname}}</td>


              <td>{{company.n_inventions}}</td>

              <td>
                <a [href]="company.webpage && company.webpage.startsWith('http') ? company.webpage : (company.webpage ? 'http://' + company.webpage : '#')"
                  target="_blank">
                  {{ company.webpage || 'No webpage available' }}
                </a>
              </td>
              <td>{{company.entityid}}</td>
              <td>{{company.companyid}}</td>
             
              <td>
                <button class="btn search-button" (click)="addCompany(company)" [disabled]="!company.subindustryname">
                  View
                </button>
              </td>
              <td *ngIf="roles!.includes('Report') ">
                <button class="btn download-button" (click)="downloadreportv1(company)"
                  [disabled]="reportDownloadingV2||reportDownloadingV1">
                  <i class="bi bi-file-earmark-arrow-down"></i> </button>

              </td>
              <td *ngIf="roles!.includes('ReportV2') ">
                <button class="btn download-button" (click)="downloadreportv2(company)"
                  [disabled]="!company.subindustryname||reportDownloadingV2||reportDownloadingV1">
                  <i class="bi bi-file-earmark-arrow-down"></i> </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;">
        </pagination-controls>

      </div>
      <!-- slected companies -->
      <br><br>
      <span class="mt-4" *ngIf="selected_companies.length>0" styles="font-size:14px"> <strong>
          Selected Companies
        </strong>

      </span>
      <div class="card mt-2" *ngIf="selected_companies.length>0">

        <table class="table table-condensed table-hover">
          <thead>
            <tr>

              <th scope="col">Name</th>

              <th scope="col">Country</th>
              <th scope="col">Region</th>
              <th scope="col">Type</th>
              <th scope="col">No. of Inventions</th>


              <th scope="col">URL</th>
              <!-- <th scope="col">First Invention Year</th>
              <th scope="col">Last Invention Year</th> -->
              <!-- <th scope="col">Score</th> -->
              <th scope="col">ISIN</th>
              <th scope="col">Ticker</th>
              <th scope="col">Entity ID</th>
              <th scope="col">Company ID</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of selected_companies  | paginate: {id:'pag_2' ,itemsPerPage: 3, currentPage: paginator_2_page }">

              <td>{{company.entity_name}}</td>

              <td>{{company.country}}</td>
              <td>{{company.region}}</td>

              <td>{{company.companytype}}</td>
              <td>{{company.n_inventions}}</td>

              <td>{{company.webpage}}</td>
              <!-- <td>{{company.year_first_active}}</td>
              <td>{{company.year_last_active}}</td> -->
              <td>{{company.isin}}</td>
              <td>{{company.tickersymbol}}</td>
              <td>{{company.entityid}}</td>
              <td>{{company.companyid}}</td>
              <!-- <td>{{company.match_score |number:'1.0-2'}}</td> -->
              <td><i class="bi bi-trash" (click)="removeCompany(company)"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_2" [maxSize]="2" (pageChange)="paginator_2_page = $event"
          style="align-items: flex-start;">
        </pagination-controls>



      </div>
      <div class="submit_button mt-4" *ngIf="selected_companies.length>0">
        <button type="button" class="btn " style="background-color:#9681d1" (click)="submitCompanies()">
          Submit
        </button>
      </div>


    </div>


  </div>


</div>