import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, tap } from 'rxjs';
import { Inject } from '@angular/core';
import { ILogin } from '../models/user.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private authenticationStatus = new BehaviorSubject<boolean>(false);
  isLoggedIn = false;
  authenticated = this.authenticationStatus.asObservable();
  changeAuthentication(authenticated: boolean) {
    this.authenticationStatus.next(authenticated);
  }
  login(username:string,password:string) {


    return this.http
      .post<ILogin>(
        "/backend/user/login",
        {username,password},
        { headers: new HttpHeaders({ "Content-Type": "application/json" }) }
      )
      .pipe(
        map((response) => {
          if (response && response["payload"]["jwt_token"]) {
            localStorage.setItem(
              "current_user_token",
              response["payload"]["jwt_token"]
            );
            localStorage.setItem(
              "user",
              JSON.stringify(response["payload"]["user"]["username"])
            );
            localStorage.setItem("timestamp", new Date().toISOString());
            localStorage.setItem("roles", JSON.stringify(response["payload"]["roles"]));
            localStorage.setItem(
              "allow_sampling",
              JSON.stringify(response["payload"]["user"]["allow_sampling"])
            );
            localStorage.setItem(
              "allow_excel_download",
              JSON.stringify(response["payload"]["user"]["allow_excel_download"])
            );
            this.isLoggedIn = true;
          }
          this.checkLogin();
          return response;
        })
      );
  }

  logout() {
    localStorage.removeItem("current_user_token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    localStorage.removeItem("allow_sampling");
    this.changeAuthentication(false);
    this.isLoggedIn = false;
  }

  checkLogin() {
    let authenticated = localStorage.getItem("current_user_token") != null;
    if (authenticated) {
      this.changeAuthentication(true);

      let lastLogin = localStorage.getItem("timestamp")!;
      let timePassed = new Date().getTime() - new Date(lastLogin).getTime();
      if (timePassed > 10 * 60 * 1000) {
         this.logout();
      }
    } else {
      this.changeAuthentication(false);
    }
  }



}
