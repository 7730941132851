import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MainNavbarComponent } from '../main-navbar/main-navbar.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MainNavbarComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
constructor(private router:Router){}

  openCompanySearch(){
    this.router.navigate(['/company-search'])
  }


  openThematicSearch(){
    this.router.navigate(['/thematic-search'])
  }

  openIdeaGeneration(){
    this.router.navigate(['/idea-generation'])
  }

}
