import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of, Observable } from 'rxjs';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState, Tab } from '../../../../../../state/app.state';
import { selectActiveTabDetails, selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { IKeywords } from '../../../../../../core/models/results.model';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-technology-footprint',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule],
  templateUrl: './technology-footprint.component.html',
  styleUrls: ['./technology-footprint.component.css']
})
export class TechnologyFootprintComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null; // Accept query
  @Input() entityids: string[] | null = null; // Accept entityids
  @Input() tab_name: string | null = null;
  constructor(private store: Store<AppState>, private apiService: GeneralInnovationActivityResultsService) { }
  tabs$!: Observable<Tab | undefined>;
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.tabs$ = this.store.select(selectActiveTabDetails);
      this.keywords_data_loading_failed=false;
      this.footprint_detailed_data_loading_failed=false;
      this.keywords=[];
      this.footprint_detailed_data=[];
      this.topKeywords();
      this.footprintDetailed();
    }
  }

  keywords: IKeywords[] = [];
  paginator_1_page: number = 1;
  paginator_2_page: number = 1;
  footprint_detailed_data: any = [];
  keywords_data_loading_failed:boolean=false;
  topKeywords() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'top_keywords')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getKeywords(this.query, this.entityids).pipe(
            tap((keywords) => {
              if(keywords.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'top_keywords',
                  data: keywords.payload
                }));

                this.keywords = keywords.payload;
              }else{
                this.keywords_data_loading_failed=true;
              }

            })
          );
        } else {
          this.keywords = data;
          this.keywords_data_loading_failed=false;
          return of();
        }
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        this.keywords_data_loading_failed=true;

      }
    });
  }

  footprint_detailed_data_loading_failed:boolean=false;
  footprintDetailed() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'footprint_detailed')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getFootprintDetailed(this.query, this.entityids).pipe(
            tap((res) => {
              if(res.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'footprint_detailed',
                  data: res.payload
                }));

                this.footprint_detailed_data = res.payload;
              }else{
                this.footprint_detailed_data_loading_failed=true;
              }

            })
          );
        } else {
          this.footprint_detailed_data_loading_failed=false;
          this.footprint_detailed_data = data;
          return of();
        }
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        this.footprint_detailed_data_loading_failed=true;

      }
    });
  }







}
