import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { logout } from '../../state/action';

export const tokenExpiryInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const store = inject(Store);  
  const router = inject(Router);  
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        alert('Session expired. Please log in again to continue.');
        logoutAndReload(store,router);
      }
      return throwError(() => error);
    })
  );
};

function logoutAndReload(store: Store, router: Router) {
  store.dispatch(logout());
  localStorage.removeItem('current_user_token');
  localStorage.removeItem('timestamp');
  localStorage.removeItem('user');
  localStorage.removeItem('roles');
  localStorage.removeItem('allow_sampling');
  window.location.reload();
  router.navigate(['/login']);
}
