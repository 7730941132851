<app-main-navbar></app-main-navbar>




<div class="container-fluid">

  <button  class="btn btn-primary" (click)="createUser()"> Create User</button>

  <br>   <br> 
  <div class="search-form">
    <form [formGroup]="searchForm">

      <input class="form-control" type="text" id="searchTerm" formControlName="searchTerm" placeholder="Enter Keywords">
      &nbsp;
    </form>
  </div>
  <br>

      <br>
      <div class="spinner-border text-primary" role="status" *ngIf="loadingData">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="table-container" style="margin:0;">
        <table class="table table-condensed table-hover" *ngIf="showUsers">
          <thead>
            <tr>
              <th style="width: 5%;">ID</th>
              <th style="width: 15%;">Organisation</th>
              <th style="width: 15%;">Username</th>
              <th style="width: 15%;">API Username</th>
              <th style="width: 10%;">Allow Sampling</th>
              <th style="width: 10%;">Allow Excel Download</th>
              <th style="width: 20%;">Roles</th>
              <th style="width: 5%;">Edit</th>
              <th style="width: 5%;">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of filteredData | paginate:{id:'pag_1', itemsPerPage: 10, currentPage: paginator_1_page}">
              <td>{{ user.id }}</td>
              <td>{{ user.organisation }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.api_username }}</td>
              <td>{{ user.allow_sampling }}</td>
              <td>{{ user.allow_excel_download }}</td>
              <td>{{ user.roles.join(', ') }}</td>
              <td><i class="bi bi-pen" (click)="editUser(user)"></i></td>
              <td><i class="bi bi-trash" (click)="deleteUser(user)"></i></td>
            </tr>
          </tbody>
        </table>
        
        <pagination-controls id="pag_1" [maxSize]="10" (pageChange)="paginator_1_page = $event"
        style="align-items: flex-start;">
      </pagination-controls>
      </div>
    </div>