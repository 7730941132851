
<h1 mat-dialog-title>Confirm Delete</h1>
<mat-dialog-content>
  <p>Are you sure you want to delete user  <strong>
    "{{ data.username }}"? </strong></p>
  <br>

  <mat-checkbox [(ngModel)]="deleteApiUser">Delete API User </mat-checkbox>
    <strong>"{{data.api_username}}"</strong>
  
  </mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
  <button class="btn btn-danger" color="warn" (click)="onConfirmClick()">Delete</button>
</mat-dialog-actions>
