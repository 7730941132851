
import { createSelector
 } from "@ngrx/store";

 import { AppState,MainState,Tab } from "./app.state";

 export const selectMainState = (state:AppState)=>state.main;

 export const selectTabList= (state:AppState)=>state.TabList;

 export const selectTabById = (tabId: string) =>
  createSelector(
    selectTabList,
    (tabList: Tab[]) => tabList.find((tab) => tab.tabId === tabId)
  );


export const selectSidenavDataByTabIdAndKey =(tabId:string,sidenavKey:string)=>createSelector(
  selectTabById(tabId),
  (tab:Tab|undefined)=>tab? tab.sidenav_results[sidenavKey]:undefined
);
export const selectActiveTabID = createSelector(
  selectMainState,
  (state: MainState) => state.activeTabId || '' // Default to an empty string if undefined
);

export const selectActiveSideTab = createSelector(
  selectMainState,
  (state: MainState) => state.activeSideTab || '' // Default to an empty string if undefined
);



export const selectActiveTabDetails = createSelector(
  selectActiveTabID,
  selectTabList,
  (activeTabId, tabList) => {
    return tabList.find(tab => tab.tabId === activeTabId);
  }
);

export const selectActiveTabSidenavData = (sidenavKey: string) =>
  createSelector(
    selectActiveTabDetails,
    (activeTab: Tab | undefined) => activeTab ? activeTab.sidenav_results[sidenavKey] : undefined
  );

//selector to check if the data exists in the store
export const selectSidenavDataExists = (tabId:string,sidenavKey:string)=>createSelector(
  selectTabById(tabId),
  (tab)=>tab? !!tab.sidenav_results[sidenavKey] : false
)
