import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree ,Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelAuthGuard implements CanActivate {
  constructor(
    private router: Router,
) {}


canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('current_user_token');

  const r=localStorage.getItem('roles');
   if(r && (r.includes("Admin")) && user && token){
    return true;
   }
 
 else{
  this.router.navigate(['/login']), { queryParams: { returnUrl: state.url } };
  return false;
 }
 

}

  
}
