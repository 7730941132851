<div class="container-fluid">
  <div class="row">
    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name | titlecase}}    </h1>

    <div class="col m-10" style="margin:20px;">
      <div style="margin-bottom:10px;">
        The table below shows companies that are active with at least 1 invention in this area in the last 20 years, ranked based on the level of their innovative output in this area.
      </div>
      <div class="card keywords" style="padding:20px;">

        <h3>Top Companies in Theme</h3>
        <div class="spinner-border text-primary" role="status" *ngIf="top_companies_data.length==0 && !top_companies_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="top_companies_loading_failed">
          <p>
            Unfortunately, we weren't able to load the top companies. Please try again later, or for more assistance, please contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>


        <table class="table   table-hover table-condensed " *ngIf="top_companies_data.length>0 && !top_companies_loading_failed">
          <thead>
            <tr style="background-color: #96c242;">
              <th scope="col">Name</th>
              <th scope="col">Country</th>
              <th scope="col">Region</th>
              <th scope="col">Type</th>



              <th scope="col">URL</th>

              <th scope="col">Ticker</th>
              <th scope="col">ISIN</th>
              <th scope="col">Inventions (in-theme 3y)</th>
              <th>Quality Score (3y)</th>



            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of top_companies_data | paginate: {id:'pag_1', itemsPerPage: 20, currentPage: paginator_1_page }">
              <td>{{company.entity_name}}</td>
              <td>{{company.country}}</td>
              <td>{{company.region}}</td>
              <td>{{company.companytype}}</td>




              <td>
                <a [href]="company.webpage && company.webpage.startsWith('http') ? company.webpage : (company.webpage ? 'http://' + company.webpage : '#')"
                  target="_blank">
                  {{ company.webpage || 'No webpage available' }}
                </a>
              </td>

              <td>{{company.tickersymbol}}</td>
              <td>{{company.isin}}</td>
              <td>{{company.invention_count}}</td>
              <td>{{company.quality_average | number: '1.1-1'}}</td>


            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="top_companies_data.length>0">
        </pagination-controls>


      </div>
    </div>





  </div>
</div>
