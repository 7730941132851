import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse, HttpInterceptorFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { logout } from '../../state/action';

export const versionCheckInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn 
): Observable<HttpEvent<any>> => {
  const store = inject(Store);  
  const router = inject(Router);  

  return next(req).pipe( 
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const serverVersion = event.headers.get('X-App-Version');
        const localVersion = localStorage.getItem('appVersion');
        if (!localVersion) {
          localStorage.setItem('appVersion', serverVersion!);
        } else if ((serverVersion && serverVersion !== localVersion)) {
          localStorage.setItem('appVersion', serverVersion);
          logoutAndReload(store, router); 
        }
      }
    })
  );
};

function logoutAndReload(store: Store, router: Router) {
  store.dispatch(logout());
  localStorage.removeItem('current_user_token');
  localStorage.removeItem('timestamp');
  localStorage.removeItem('user');
  localStorage.removeItem('roles');
  localStorage.removeItem('allow_sampling');
  alert(' We have updated our application to a new version. Please log in again to continue.');
  window.location.reload();
  router.navigate(['/login']);
}
