import { createAction, props } from '@ngrx/store';
import { Tab } from './app.state';

export const changeActiveSideTab = createAction(
  '[Main] Change Active Side Tab',
  props<{ sideTab: string }>()
);

export const changeActiveTab = createAction(
  '[Main] Change Active Tab',
  props<{ tabId: string }>()
);

export const updateSidenavResults = createAction(
  '[Tabs] Update Sidenav Results',
  props<{ tabId: string; sidenavKey: string; data: any }>()
);


export const loadSidenavData = createAction(
  '[Results] Load Sidenav Data',
  props<{ tabId: string; sidenavKey: string; data: any }>()
);


export const submitSearch = createAction(
  '[Search] submit',
  props<{searchParams:any;searchType:'company'|'theme'}>(

  )
);

// Action to add a tab
export const addTab = createAction(
  '[App] Add Tab',
  props<{ tab: Tab }>()
);
export const setActiveTab = createAction(
  '[Tab] Set Active Tab',
  props<{ tab: Tab }>() // Accept a Tab object as payload
);

export const navigateToResults = createAction('[Search] Navigate To Results');

export const loadSidenavDataForActiveTab = createAction(
  '[Tabs] Load Sidenav Data for Active Tab',
  props<{ tabId: string; sideTab: string }>()
);


export const removeTab = createAction(
  '[Tab] Remove Tab',
  props<{tabId:string}>()
);


export const logout = createAction('[Auth] Logout')