import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectTabList } from '../../../state/selectors';
import { AppState, Tab } from '../../../state/app.state';
import { logout } from '../../../state/action';


@Component({
  selector: 'app-main-navbar',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css'],
})
export class MainNavbarComponent implements OnInit{
  // Observable to track tabs presence
  hasTabs$: Observable<boolean>;
  roles:string |null=null;

  constructor(private store: Store<AppState>,private router:Router) {

    this.hasTabs$ = this.store.select(selectTabList).pipe(
      map((tabs: Tab[]) => tabs && tabs.length > 0)
    );
  }
  ngOnInit(): void {
    this.roles=JSON.parse(localStorage.getItem('roles')!);
  }

  logout() {
    this.store.dispatch(logout());
    localStorage.removeItem("current_user_token");
    localStorage.removeItem("timestamp");
    localStorage.removeItem("user");
    localStorage.removeItem("roles");
    localStorage.removeItem("allow_sampling");
    this.router.navigate(['/login']);
  }

}
