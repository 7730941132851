import { ITrends } from '../../../../core/models/thematic.model';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


import { PlotlyModule } from 'angular-plotly.js';
import { IdeaGenerationServiceService } from '../../services/idea-generation-service.service';
import { Store } from '@ngrx/store';
import { submitSearch } from '../../../../state/action';


@Component({
  selector: 'app-theme-trends',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PlotlyModule],
  templateUrl: './theme-trends.component.html',
  styleUrl: './theme-trends.component.css',
})
export class ThemeTrendsComponent implements OnInit {
  @Input() market!: string;
  constructor(private thematicService: IdeaGenerationServiceService,private store:Store) {

  }
  selectedMarket: string = "developed";

  themeCataloguePlot!: { data: { type: string; labels: string[] | undefined; parents: string[] | undefined; ids: string[] | undefined; values: number[] | undefined; branchvalues: string; text: string[]; title: string; pathbar: { visible: boolean; }; marker: { colors: string[]; cmin: number; cmax: number; colorbar: { title: { text: string; }; }; showscale: boolean; colorscale: (string | number)[][]; }; insidetextfont: { family: string; size: number; }; }[]; layout: { margin: { t: number; l: number; r: number; b: number; }; autosize: boolean; responsive: boolean; }; };
  showSpinner: boolean = false;
  showPlot: boolean = false;
  trendsData:any=null;
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['market'] && changes['market'].currentValue) {
      this.selectedMarket = changes['market'].currentValue;
      if (this.selectedMarket === 'developed') {
        this.getDevelopedMarkets();
      } else if (this.selectedMarket === 'global') {
        this.getGlobalMarkets();
      }
    }
  }




  getDevelopedMarkets() {
    this.showSpinner = true;
    this.thematicService.getDevelopedMarketsData().subscribe({
      next: (res) => {
        this.trendsData=res.payload;
        this.cmax = 10;
        this.cmin = 0;
        this.plotGraph(res.payload);
      },
      error: (err) => {
        alert("Developed markets trends loading failed!")
        this.showSpinner = false;
      },
    });
  }

  getGlobalMarkets() {
    this.showSpinner = true;
    this.thematicService.getGlobalMarketsData().subscribe({
      next: (res) => {
        this.trendsData=res.payload;
        this.cmax = 10;
        this.cmin = 0;
        this.plotGraph(res.payload);
      },
      error: (err) => {
        alert("Global markets trends loading failed!")
        this.showSpinner = false;
      },
    });
  }


  cmin: number = 0;
  cmax: number = 20;
  plotGraph(data: ITrends) {
    const ids = data.ids;
    const parents = data.parent;
    const labels = data.child;
    const values = data.growth;
    const growth_percentage = data.growth_percentage!.map(growth_percentage => growth_percentage.toFixed(2));
    const query = data.query;
    const keywords = data.keywords;

    const companies = data.companies;
    const treemapData = [
      {
        type: "treemap",
        labels: labels,
        parents: parents,
        ids: ids,
        values: values,
        branchvalues: 'total',
        text: labels!.map((label, i) => {
          const formattedKeywordsForRow = keywords && keywords[i] ? keywords[i].split(',<br>').slice(0, 5).join('<br>') : '';
          const formattedTopCompanies = companies && companies[i]
            ? companies[i].split(',<br>').slice(0, 5).join('<br>')
            : '';

          return `<b>Growth:</b> ${values![i]}<br><b>Growth Percentage:</b> ${growth_percentage![i]}%<br><br><b>Top Keywords:</b> ${formattedKeywordsForRow}<br><br><b>Top Companies:</b> ${formattedTopCompanies}`;
        }),

        title: "<sup>Box Size = Absolute Growth , Color = Relative Growth(%)</sup>",
        pathbar: { "visible": true },

        marker: {
          colors: growth_percentage,
          cmin: this.cmin,
          cmax: this.cmax,

          colorbar: { title: { text: "growth%" } },
          showscale: true,
          colorscale: [
            [0.0, "rgb(5,48,97)"],      // Darkest blue
            [0.05, "rgb(33,102,172)"],  // Darker blue
            [0.10, "rgb(67,147,195)"],  // Dark blue
            [0.15, "rgb(146,197,222)"], // Medium blue
            [0.20, "rgb(209,229,240)"], // Light blue
            [0.25, "rgb(247,247,247)"], // White (middle point)
            [0.50, "rgb(253,219,199)"], // Light red
            [0.65, "rgb(244,165,130)"], // Medium red
            [0.80, "rgb(214,96,77)"],   // Dark red
            [0.90, "rgb(178,24,43)"],   // Darker red
            [1.0, "rgb(103,0,31)"]      // Darkest red
          ]

        },
        insidetextfont: {
          family: '"Open Sans", verdana, arial, sans-serif',
          size: 18,

        }
      },
    ];

    var layout = {
      margin: { t: 25, l: 25, r: 25, b: 25 },
      autosize: true,
      responsive: true,
       height: 700,
    };

    this.themeCataloguePlot = {
      data: treemapData,
      layout: layout,
    };
    this.showSpinner = false;
    this.showPlot = true;
  }



  selectedThemeQuery: string = ' ';
  selectedTheme:string='';
  showTheme:Boolean=false;
  handleClick(event: any) {
    const clickedData = event.points[0];
    this.selectedTheme = clickedData.label;
    this.showTheme = true;
    const clickedId = clickedData.id;
    const data = this.trendsData;
    const dataIndex = data.ids.indexOf(clickedId);
    this.selectedThemeQuery = data.query[dataIndex];

  }

  submitTheme(){
    const searchParams = { 'themes': [{ 'api_query': this.selectedThemeQuery ,'name':this.selectedTheme}] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }
}
