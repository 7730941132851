import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IinnovationQuality, IPeerGroupCompetitors } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-peer-group',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule],
  templateUrl: './peer-group.component.html',
  styleUrl: './peer-group.component.css'
})
export class PeerGroupComponent implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() gsubind: string[] | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store:Store<AppState>,private apiService:GeneralInnovationActivityResultsService
  ){

  }

ngOnInit(): void {
}
showResults:boolean=false;
ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.showResults=false;
    this.competitors_list=[];
    this.competitors_list_loading_failed=false;

    if(this.gsubind && this.gsubind[0]!=null){
      this.getCompetitors();
    }else{
      this.showResults=false;
    }

  }
}



competitors_list_loading_failed:boolean = false;
competitors_list: IPeerGroupCompetitors[] = [];
paginator_1_page:number=1;
matchingCompetitor: IPeerGroupCompetitors | null = null;

getCompetitors() {
  this.showResults = true;
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'company_detailed_technology_benchmark')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.apiService.getCompetitiveBenchmarkingPeerGroup(this.entityids, this.gsubind).pipe(
          tap((res) => {
            if (res.payload != null) {
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'company_detailed_technology_benchmark',
                data: res.payload
              }));
              const en_id = this.entityids![0];
              this.competitors_list = res.payload;

              this.matchingCompetitor = this.competitors_list.find(
                (competitor) => en_id === competitor.entityid
              ) || null;

              if (this.matchingCompetitor) {
                // Remove the matching competitor from the list if found, so we can display it separately
                this.competitors_list = this.competitors_list.filter(
                  (competitor) => competitor.entityid !== en_id
                );
              }

            } else {
              this.competitors_list_loading_failed = true;
            }
          })
        );
      } else {
        this.competitors_list_loading_failed = false;
        this.competitors_list = data;
        const en_id = this.entityids![0];
        this.matchingCompetitor = this.competitors_list.find(
          (competitor) => en_id === competitor.entityid
        ) || null;
        if (this.matchingCompetitor) {
          // Remove the matching competitor from the list if found, so we can display it separately
          this.competitors_list = this.competitors_list.filter(
            (competitor) => competitor.entityid !== en_id
          );
        }
        return of();
      }
    })
  ).subscribe({
    next: () => {
      // console.log('company_detailed_technology_benchmark processing complete.');
    },
    error: (err) => {
      this.competitors_list_loading_failed = true;
    }
  });
}


}
