<app-main-navbar></app-main-navbar>
<div class="container-fluid">
  <!-- <div class="row align-items-center" style="height:60px;background-color: #ffffff;">
    <div class="col-2">
      <img src="assets/quant_ip_logo.png" class="img-fluid logo-img mt-2">

    </div>
    <div class="col">

      <button type="button" class="btn " style="background-color:#FEF7FF ;">
       Thematic Search - Custom Search
      </button>

    </div>
  </div> -->
  <div class="row">
    <div class="col">

        <div class="card shadow" style="width: 30rem;left:35%;top:40%;">
            <div class="form-check form-switch toggle mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                [checked]="searchtype === 'advanced'"
                (change)="onSearchTypeToggle($event)">
              <label class="form-check-label" for="flexSwitchCheckDefault" style="color: blue;">
                Switch to {{ searchtype === 'normal' ? 'Advanced' : 'Normal' }} mode
              </label>
            </div>



          <div class="card-body" *ngIf="searchtype==='normal'">
            <h5 class="card-title">Start a new theme</h5>
            <p class="card-text">Please enter a search phrase below that you would like to start with. We will translate
              that into a query for you.<br>    <br>
              Please enter a single concept in only one or two words. You will be able to customize your search in the
              next steps.</p>

            <p>Example: Electric Vehicles</p>
            <div style="display:flex; align-items: center; gap: 10px;">
              <input [formControl]="custom_query" type="text" class="form-control" style="flex-grow: 1; height: 40px; font-size: 16px;" placeholder="Enter search phrase" >
              <button class="btn search-button" style="height: 40px; padding: 0 20px;" (click)="searchTheme('normal')">
                Search
              </button>
            </div>
            <br>
          </div>

          <div class="card-body "  *ngIf="searchtype==='advanced'">
            <h5 class="card-title">Advanced</h5>
            <p class="card-text">Please enter a direct search query in the style of ESPACENET patent search queries. <br>
              Please avoid using the advanced mode if you aren’t familiar with the ESPACENET search syntax. </p>
              <p>You can read more about the search syntax <a href="https://docs.google.com/document/d/1IH3abhjZLpNdN7Yi3TRDaqIiBPz55KUCEEyrKKInaRw/edit?usp=sharing" target="_blank">here</a>.</p>
            <p>Example:ftxt="Electric Vehicles"</p>
            <div style="display:flex; align-items: center; gap: 10px;">
              <input [formControl]="advanced_query" type="text" class="form-control" style="flex-grow: 1; height: 40px; font-size: 16px;" placeholder="Enter search phrase" >
              <button class="btn search-button" style="height: 40px; padding: 0 20px;" (click)="searchTheme('advanced')">
                Search
              </button>
            </div>
            <br>
          </div>
        </div>

    </div>
  </div>
</div>
