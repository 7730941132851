


<h1 mat-dialog-title id="exampleModalLabel">{{ user_id ? 'Edit User' : 'Create User' }}</h1>
<mat-dialog-content>
  <form [formGroup]="userForm">
    <div class="mb-3">
      <label for="organisation" class="form-label"><strong>Organisation:</strong></label>
      <input type="text" class="form-control" id="organisation" formControlName="organisation" />
    </div>

    <div class="mb-3">
      <label for="username" class="form-label"><strong>User Name:</strong></label>
      <input type="text" class="form-control" id="username" formControlName="username" />
    </div>

    <div class="mb-3">
      <label for="password" class="form-label"><strong>Password:</strong></label>
      <div class="input-group">
        <input class="form-control" id="password" formControlName="password" />
        <button class="btn btn-outline-secondary" type="button" (click)="generateRandomPassword('password'); $event.stopPropagation()">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
    </div>

    <div class="mb-3">
      <label for="apiUsername" class="form-label"><strong>API Username:</strong></label>
      <input type="text" class="form-control" id="apiUsername" formControlName="api_username" />
    </div>

    <div class="mb-3">
      <label for="apiPassword" class="form-label"><strong>API Password:</strong></label>
      <div class="input-group">
        <input  class="form-control" id="apiPassword" formControlName="api_password" />
        <button class="btn btn-outline-secondary" type="button" (click)="generateRandomPassword('api_password'); $event.stopPropagation()">
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
    </div>

    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" formControlName="allow_sampling" id="allow_sampling" />
      <label class="form-check-label" for="allow_sampling"><strong>Allow Sampling</strong></label>
    </div>

    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" formControlName="allow_excel_download" id="allow_excel_download" />
      <label class="form-check-label" for="allow_excel_download"><strong>Allow Excel Download</strong></label>
    </div>

    <strong>Roles:</strong>
    <div class="mb-3">
      <div *ngFor="let role of allRoles" class="form-check">
        <input type="checkbox" class="form-check-input" (change)="toggleRole(role)" [checked]="selectedRoles.includes(role)" id="{{ role }}" />
        <label class="form-check-label" for="{{ role }}">{{ role }}</label>
      </div>
    </div>

    <p *ngIf="user_id" style="color: blue; font-style: italic">
      <strong>Note:</strong> Current API roles are not displayed while editing the user. If you intend to modify API roles, please utilize the section below. Ensure to include all necessary roles when making changes.
    </p>

    <strong>API Roles:</strong>
    <div class="mb-3">
      <div *ngFor="let role of availableAPIRoles" class="form-check">
        <input type="checkbox" class="form-check-input" (change)="toggleAPIRole(role)" [checked]="selectedAPIRoles.includes(role)" id="{{ role }}_api" />
        <label class="form-check-label" for="{{ role }}_api">{{ role }}</label>
      </div>
    </div>
  </form>
</mat-dialog-content>


<mat-dialog-actions>

  <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
  <div *ngIf="saveRunning">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <button type="button" class="btn btn-primary" (click)="onSaveClick()" [disabled]="userForm.invalid">Save</button>
</mat-dialog-actions>
