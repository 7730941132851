import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../state/app.state';
import { changeActiveSideTab, changeActiveTab, removeTab, setActiveTab } from '../../../../state/action';
import { selectActiveTabID, selectTabList } from '../../../../state/selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class TabComponent implements OnInit {
  tabs$!: Observable<any[]>;
  activeTab$!: Observable<string>;

  constructor(private store: Store<AppState>,private router:Router) {}

  ngOnInit(): void {
    this.tabs$ = this.store.select(selectTabList);
    this.activeTab$ = this.store.select(selectActiveTabID);
    // this.tabs$.subscribe(tabs => console.log('Tabs:', tabs));
    // this.activeTab$.subscribe(activeTab => console.log('Active Tab:', activeTab));
  }

  onTabClick(tabId: string): void {
    this.store.dispatch(changeActiveTab({ tabId }));
  }

  removeTab(tabId:string){
    this.store.dispatch(removeTab({tabId}));
  }
}
