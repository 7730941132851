import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { Store } from '@ngrx/store';
import { submitSearch } from '../../../../state/action';

@Component({
  selector: 'app-custom-search',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MainNavbarComponent],
  templateUrl: './custom-search.component.html',
  styleUrl: './custom-search.component.css'
})
export class CustomSearchComponent {
  constructor(private store: Store) {

  }
  custom_query = new FormControl<string>(" ");
  advanced_query = new FormControl<string>(" ");
  searchtype: string = 'normal';
  ngOnInit() {

  }
  onSearchTypeToggle(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchtype = inputElement.checked ? 'advanced' : 'normal';
  }


  searchTheme(searchtype: string) {
    if (searchtype === 'normal') {

      const ftxtQuery = `ctxt="${this.custom_query.value}"`
      const searchParams = { 'themes': [{ 'api_query': ftxtQuery,'name':this.custom_query.value }] };

      this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));

    } else {
      const searchParams = { 'themes': [{ 'api_query': this.advanced_query.value ,'name':this.advanced_query.value}] };

      this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
    }

  }

}
