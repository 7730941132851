import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { removeTab, setActiveTab, changeActiveSideTab, changeActiveTab } from './action';
import { AppState, companySideNavList, themeSideNavList } from './app.state';




@Injectable()
export class TabEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private router: Router) {}

  // Effect to handle tab removal and state transition
  removeTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTab),
      withLatestFrom(this.store.select('main'), this.store.select('TabList')),
      switchMap(([action, main, tabList]) => {
        const { activeTabId } = main;
        const remainingTabs = tabList.filter(tab => tab.tabId !== action.tabId);

        if (remainingTabs.length === 0) {
          // No tabs are left, navigate to the home page
          this.router.navigate(['/home']);
          return [];
        } else if (activeTabId === action.tabId) {
          // If the removed tab was the active one, set the next available tab as active
          const nextTab = remainingTabs[0];
          return [
            setActiveTab({ tab: nextTab }),
            changeActiveSideTab({ sideTab: 'innovation_activity' })
          ];
        }
        return [];
      })
    )
  );



  //effect to make sure the active tabs has a proper active sidetab
  validateActiveSideTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeActiveTab),
      withLatestFrom(this.store.select('TabList')),
      switchMap(([action, tabs]) => {
        const { tabId } = action;
        const activeTab = tabs.find(tab => tab.tabId === tabId);

        if (!activeTab) {
          return of();
        }

        const { search_type } = activeTab;
        const allowedSideTabs = search_type === 'company' ? companySideNavList : themeSideNavList;

        return this.store.select('main').pipe(
          map(mainState => mainState.activeSideTab),
          switchMap((activeSideTab) => {
            if (!allowedSideTabs.includes(activeSideTab)) {
              // If the active side tab isn't allowed for this tab type, change it to the first one in the allowed list
              const newSideTab = allowedSideTabs[0];
              return of(changeActiveSideTab({ sideTab: newSideTab }));
            }
            return of();
          })
        );
      })
    )
  );
}
