<ul class="nav nav-tabs custom-tabs">
  <li class="nav-item" *ngFor="let tab of (tabs$ | async)" style="text-align: left;">
    <a class="nav-link tabss"
       [class.active]="tab.tabId === (activeTab$ | async)"
       (click)="onTabClick(tab.tabId)"> <button type="button" class="btn-close" (click)="removeTab(tab.tabId)" style = "color:blue;"></button>
   {{tab.tab_name}}

    </a>

  </li>
</ul>

