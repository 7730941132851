import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Iuser } from '../../../../core/models/user.model';
import { AdminPanelService } from '../../admin-panel.service';
import { EditFormComponent } from '../edit-form/edit-form.component';
import { CommonModule } from '@angular/common';
import { DeleteConfirmPopupComponent } from '../delete-confirm-popup/delete-confirm-popup.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-users-table',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, ReactiveFormsModule, FormsModule,MatFormFieldModule, MatSelectModule ,MatSlideToggleModule,MainNavbarComponent],
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.css'
})
export class UsersTableComponent {


  constructor(private adminPanelService: AdminPanelService, private dialog: MatDialog, private fb: FormBuilder,) {
    this.searchForm = this.fb.group({
      searchTerm: ['']
    });
  }

  ngOnInit() {
    this.getAllUsers();
    this.getAllRoles();
    this.searchForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  paginator_1_page: number = 1;
  users: Iuser[] = [];
  showUsers: boolean = false;
  loadingData: boolean = false;
  getAllUsers() {
    this.loadingData = true;
    this.showUsers = false;
    this.adminPanelService.getUsers().subscribe({
      next: (res) => {
        this.users = res.payload;
        this.applyFilters();
        this.showUsers = true;
        this.loadingData = false;
      },
      error: () => {
        this.showUsers = false;
        this.loadingData = false;
        alert("failed to load data");
      }
    })
  }
  allRoles: string[] = [];

  getAllRoles() {
    this.adminPanelService.getAllRoles().subscribe({
      next: (res: { payload: string[]; }) => {
        this.allRoles = res.payload;
      },
      error: () => {

      }
    })
  }


  createUser() {
    const dialogRef = this.dialog.open(EditFormComponent, {
      data: {
        allRoles: this.allRoles
      },
      width: '600px',
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }
  editUser(user: Iuser) {
    if (this.allRoles.length < 1) {
      this.getAllRoles();
    }

    const dialogRef = this.dialog.open(EditFormComponent, {
      data: {
        allRoles: this.allRoles,
        user: user
      },
      disableClose: true,
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  deleteUser(user: Iuser) {
    const dialogRef = this.dialog.open(DeleteConfirmPopupComponent, {
      width: '300px',
      data: { username: user.username, api_username: user.api_username },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed === true) {
        this.loadingData = true;
        this.showUsers = false;
        this.adminPanelService.deleteUser(user.id, result.deleteApiUser, user.api_username).subscribe({
          next: (res) => {
            this.getAllUsers();
            alert("User deleted!");
          },
          error: (err) => {
            this.showUsers = true;
            this.loadingData = false;
            alert("Failed to delete user");
          },
        });
      }
    });

  }

  searchForm: FormGroup;
  filteredData:  Iuser[] = [];

  applyFilters() {
    this.filteredData=this.users;
    const searchTerm = this.searchForm.get('searchTerm')?.value.toLowerCase() || '';
    let data = this.users;
    data = data.filter(item => {
      return (
        item.organisation.toLowerCase().includes(searchTerm) ||
        item.username.toLowerCase().includes(searchTerm)
      );
    });


    this.filteredData = data;


  }

} 