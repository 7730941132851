import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AppState, Tab } from '../../../../state/app.state';
import { Store } from '@ngrx/store';
import { selectActiveSideTab, selectActiveTabID, selectTabList } from '../../../../state/selectors';
import { changeActiveSideTab } from '../../../../state/action';

@Component({
  selector: 'app-results-sidebar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  sidebarItems: { 
    [key: string]: { 
      display: string; 
      value: string; 
      requiresInternalUser?: boolean; 
      subOptions?: { display: string; value: string }[] 
    }[] 
  } = {
    'Company View': [
      { display: 'Company Overview', value: 'executive_summary' },
      {
        display: 'Innovation Activity', value: 'innovation_activity', subOptions: [
          { display: 'Innovation Activity', value: 'innovation_activity' },
          { display: 'Technological Footprint', value: 'tech_footprint' },
          { display: 'Innovation Quality', value: 'innovation_quality' },
        ]
      },
      {
        display: 'Competitive Benchmarking', value: 'competitive_benchmarking', subOptions: [
          { display: 'Peer Group', value: 'competitive_benchmarking_peergroup' },
        ]
      },
      {
        display: 'Quant IP Clusters', value: 'competitive_benchmarking', requiresInternalUser: true,  // Show only for internal users
        subOptions: [
          { display: 'Innovation Activity', value: 'clustering_innovation_activity' },
          { display: 'Technology Footprint', value: 'clustering_tech_footprint' },
          { display: 'Peer Group', value: 'clustering_peer_group' },
        ]
      },
      { display: 'Cluster Overview', value: 'cluster_treemap', requiresInternalUser: true },  // Show only for internal users
    ],
    'Thematic View': [
      {
        display: 'Innovation Activity', value: '', subOptions: [
          { display: 'Innovation Activity', value: 'innovation_activity' },
          { display: 'Competition Overview', value: 'competitive_overview' },
        ]
      },
      {
        display: 'Technology Breakdown', value: 'technology_breakdown', subOptions: [
          { display: 'Technology Overview', value: 'tech_footprint' },
        ]
      },
      { display: 'Cluster Overview', value: 'cluster_treemap', requiresInternalUser: true },  // Show only for internal users
    ],
    'Portfolio View': [
      {
        display: 'Browse Inventions', value: '', subOptions: []
      },
    ],
  };
  
  isInternalUser: any;

  formatSectionId(section: string): string {
    return section.replace(/\s+/g, '-');
  }

  objectKeys = Object.keys;

  activeTabId$!: Observable<string>;
  activeSideTab$!: Observable<string | null>;
  activeSearchType$!: Observable<string | undefined>;
  tabList$!: Observable<Tab[]>;

  constructor(private store: Store<AppState>) { }
  private subscriptions: Subscription = new Subscription();

  roles:string |null=null;

  ngOnInit() {
    this.roles=JSON.parse(localStorage.getItem('roles')!);
    this.isInternalUser = this.roles!.includes('alpha');

    this.activeTabId$ = this.store.select(selectActiveTabID);
    this.activeSideTab$ = this.store.select(selectActiveSideTab);

    this.activeSearchType$ = this.activeTabId$.pipe(
      switchMap(activeTabId => {
        return this.store.select(selectTabList).pipe(
          map(tabs => {
            const activeTab = tabs.find(tab => tab.tabId === activeTabId);
            return activeTab ? activeTab.search_type : undefined;
          })
        );
      })
    );
    this.tabList$ = this.store.select(selectTabList).pipe(
      map(tabs => tabs.filter(tab => !!tab)) // Filter for non-empty tabs
    );

    this.subscriptions.add(this.activeTabId$.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  onSidebarOptionClick(event: MouseEvent, option: string): void {
    event.preventDefault();
    this.store.dispatch(changeActiveSideTab({ sideTab: option }));
  }

  isActive(value: string): boolean {
    let activeTab: string | null = null;

    this.activeSideTab$.subscribe(tab => {
      activeTab = tab;
    });

    return activeTab === value;
  }

}
