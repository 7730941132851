import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirm-popup',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,MatDialogModule,MatCheckboxModule],
  templateUrl: './delete-confirm-popup.component.html',
  styleUrl: './delete-confirm-popup.component.css',
  
})
export class DeleteConfirmPopupComponent implements OnInit {
  deleteApiUser: boolean = false;

  constructor( public dialogRef: MatDialogRef<DeleteConfirmPopupComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close({confirmed:false,deleteApiUser:false})
  }

  onConfirmClick(): void {
    this.dialogRef.close({ confirmed: true, deleteApiUser: this.deleteApiUser });

  }
}
