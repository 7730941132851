import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Iuser } from '../../../../core/models/user.model';
import { AdminPanelService } from '../../admin-panel.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-edit-form',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,MatDialogModule,MatButtonModule,MatCheckboxModule],
  templateUrl: './edit-form.component.html',
  styleUrl: './edit-form.component.css',
  // providers: [
  //   { provide: MAT_DIALOG_DATA, useValue: {} } 
  // ]
})
export class EditFormComponent implements OnInit {

  userForm!: FormGroup;

  user_id: number | undefined;
  allRoles: string[] = [];
  selectedRoles: string[] = [];
  selectedAPIRoles: string[] = [];
  saveRunning: boolean = false;
  availableAPIRoles: string[] = ['Admin', 'Alpha', 'Report', 'Thematic', 'Private'];

  constructor(private adminPanelService: AdminPanelService,
    @Inject(MAT_DIALOG_DATA) public data: { user: Iuser | null, allRoles: string[] },public dialogRef:MatDialogRef<EditFormComponent>) { }

  ngOnInit(): void {

    this.userForm = new FormGroup({
      organisation: new FormControl(this.data?.user?.organisation || ''),
      username: new FormControl(this.data?.user?.username || ''),
      password: new FormControl(''),
      api_username: new FormControl(this.data?.user?.api_username || ''),
      api_password: new FormControl(''),
      allow_sampling: new FormControl(this.data?.user?.allow_sampling || true),
      allow_excel_download: new FormControl(this.data?.user?.allow_excel_download || true),
    });

    this.selectedRoles = this.data?.user?.roles || [];
    this.selectedAPIRoles = [];
    this.user_id = this.data?.user?.id;
    this.allRoles = this.data?.allRoles;

    if (!this.user_id) {
      // Default values for new users
      this.userForm.get('allow_sampling')?.setValue(true);
      this.userForm.get('allow_excel_download')?.setValue(true);
      this.selectedAPIRoles = ['Report', 'Thematic'];
      this.selectedRoles = ['ThematicV3', 'Trends', 'ReportV2'];
    }
  }

  toggleRole(role: string) {
    if (this.selectedRoles.includes(role)) {
      this.selectedRoles = this.selectedRoles.filter(r => r !== role);
    } else {
      this.selectedRoles.push(role);
    }
  }

  toggleAPIRole(role: string) {
    if (this.selectedAPIRoles.includes(role)) {
      this.selectedAPIRoles = this.selectedAPIRoles.filter(r => r !== role);
    } else {
      this.selectedAPIRoles.push(role);
    }
  }

  randomPasswordGenerator(): string {
    const length = 32;
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  generateRandomPassword(field: string) {
    const newPassword = this.randomPasswordGenerator();
    this.userForm.get(field)?.setValue(newPassword);
  }

  onSaveClick() {
    this.saveRunning = true;
    const userData = this.userForm.value;
    const roles = this.selectedRoles;

    if (this.selectedAPIRoles.length > 0) {
      userData.api_roles = this.selectedAPIRoles;
    } else {
      delete userData.api_roles;
    }

    if (this.user_id) {
      if (!userData.password) {
        delete userData.password;
      }

      if (!userData.api_password) {
        delete userData.api_password;
      }
      this.adminPanelService.updateUser(userData, roles, this.user_id!).subscribe({
        next: (res) => {
          this.saveRunning = false;
          alert('User Updated successfully!!');
          this.dialogRef.close();
        },
        error: () => {
          alert('Failed to update user!!');
          this.dialogRef.close();

          this.saveRunning = false;
        }
      });
    } else {
      this.adminPanelService.createUser(userData, roles).subscribe({
        next: (res) => {
          this.saveRunning = false;
          alert('User created successfully!!');
          this.dialogRef.close();

        },
        error: (err) => {
          this.saveRunning = false;
          this.dialogRef.close();
          if(err.status === 409){
            alert("Can't create user: a user with this username already exists.");
          }else{
            alert('Failed to create user!');
          }

        }
      });
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
