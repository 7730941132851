import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrolesResponse, IUserResponse, IUsersResponse } from '../../core/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AdminPanelService {

  constructor(private http:HttpClient) { }


  getUsers() {
    return this.http.get<IUsersResponse>('/backend/adminPanel/users', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      responseType: 'json'
    })
  }

  deleteUser(id: number,deleteAPIUser:boolean,api_username:string) {
    const body={
     id:id,
     deleteAPIUser:deleteAPIUser,
     api_username:api_username
     }

    return this.http.post('/backend/adminPanel/users/delete',body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      reportProgress: true,
      responseType: 'json',
    }
    )
  }

  createUser(formData:any,roles:string[],){
    const body={
      ...formData,
      roles:roles,
     }
    return this.http.post<IUserResponse>('/backend/adminPanel/users/create',body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      reportProgress: true,
      responseType: 'json',
    }
    )

  }

  updateUser(formData:any,roles:string[],id:number|undefined){
   const body={
    ...formData,
    roles:roles,
    id:id
   }
    return this.http.post<IUserResponse>('/backend/adminPanel/users/update',body, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      reportProgress: true,
      responseType: 'json',
    }
    )
  }
  getAllRoles(){
  
    return this.http.get<IrolesResponse>('/backend/adminPanel/users/roles', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("current_user_token"),
      },
      reportProgress: true,
      responseType: 'json',
    }
    )
  }
}
